<template>
  <div>
    <div class="head">
      <div class="head-left" @click="goOnlineExpert">
        <img style="width: 158.34px; height: 45px" src="../../assets/mainLogo.png" alt="" />
      </div>
      <p class="head-right" @click="goPageReg">join us!</p>
    </div>
    <div class="middle">
      <div class="content">
        <div class="content-head">
          <div class="head-login">Forgot password</div>
        </div>
        <div class="content-mid">
          <p class="mid-icon"></p>
          <p class="mid-text">
            To reset your password enter your emall address. You will receive an e-mall
            with further instructions
          </p>
        </div>
        <div class="mid-login">
          <el-form
            ref="loginFromRef"
            :model="loginFnForm"
            status-icon
            :rules="loginRules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              v-if="isSend"
              label="Email:"
              label-position="left"
              prop="email"
              class="login-input"
            >
              <el-input v-model="loginFnForm.email" type="email" style="width: 250px" />
              <el-button
                size="small"
                style="
                  height: 33px;
                  width: 50px;
                  background-color: #548e9b;
                  border-color: white;
                  color: white;
                  font-size: 12px;
                "
                :loading="sendLoad"
                v-if="isSend"
                @click="sendEmail"
                >SEND</el-button
              >
              <span v-if="isShowCodeText" style="color: red">Email not registered</span>
            </el-form-item>
            <el-form-item v-if="isShowCode" label="Code:" prop="code" class="login-input">
              <el-input v-model="loginFnForm.code" style="width: 250px" type="text" />
              <el-button
                size="small"
                :loading="nextLoad"
                :disabled="LoginDisable"
                style="
                  height: 33px;
                  width: 50px;
                  background-color: #548e9b;
                  border-color: white;
                  color: white;
                  font-size: 12px;
                "
                @click="loginFn"
                >NEXT</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="mid-footer">
          <p class="foot1">Don't have an account?</p>
          <p class="foot2" @click="goPageReg">Join us now!</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
import Footer from "../.././layout/components/Footer.vue";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { conLogin } from "@/api/sys.js";
import { ElMessage } from "element-plus";
import { setToken, setType, setConlant, getType } from "@/utils/auth";
import { forgotPassAPI, forgotCodeAPI } from "../../api/sys.js";
import request from "@/utils/request.js";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
//code显示隐藏问题
const isShowCode = ref(false);
//邮箱错误情况提示
const isShowCodeText = ref(false);
//send 显示隐藏
const isSend = ref(true);
//邮箱验证
const checkEmail = (rule, value, callback) => {
  // const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
  const mailReg = /^[a-zA-Z0-9_.-]+(_?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
  if (!value) {
    return callback(new Error("Email cannot be empty"));
  }
  setTimeout(() => {
    if (mailReg.test(value)) {
      callback();
    } else {
      callback(new Error("Please enter the correct email format"));
    }
  }, 100);
};
//密码验证
const validatePass = (rule, value, callback) => {
  const pwdReg = /^\w{6}$/;
  if (!value) {
    return callback(new Error("Please enter the Code"));
  } else if (pwdReg.test(value)) {
    callback();
  } else {
    callback(new Error("Please enter 6 characters"));
  }
};

const loginRules = ref({
  email: [{ required: true, validator: checkEmail, trigger: "blur" }],
  code: [{ required: true, validator: validatePass, trigger: "blur" }],
});
onMounted(() => {
  window.scrollTo(0, 0);
});
const createLoading = ref(false);
const LoginDisable = ref(false);
const goPageReg = () => {
  router.push("/pageReg");
};
const loginFnForm = ref({
  email: "",
  code: "",
});
const sendLoad = ref(false);
//获取验证码
const sendEmail = async () => {
  if (!loginFnForm.value.email) return;
  sendLoad.value = true;
  let res = await forgotPassAPI({ email: loginFnForm.value.email });
  if (res.data == "Email not registered") {
    isShowCodeText.value = true;
  }
  if (res.data.toLowerCase() == "email send") {
    sendLoad.value = false;
    ElMessage({
      showClose: true,
      message: "Email sent successfully",
      type: "success",
    });
    isShowCode.value = true;
    isSend.value = false;
  }
  console.log(res.data);
};
//顾问登录
const loginFromRef = ref(null);
const nextLoad = ref(false);
const loginFn = () => {
  loginFromRef.value.validate(async (valid) => {
    if (valid) {
      nextLoad.value = true;
      createLoading.value = true;
      LoginDisable.value = true;
      const res = await forgotCodeAPI({
        email: loginFnForm.value.email,
        code: loginFnForm.value.code,
      });
      console.log(res);
      if (res.data == "Email send") {
        nextLoad.value = false;
        ElMessage({
          showClose: true,
          message: "Successful verification",
          type: "success",
        });
        router.push("/login");
        // setToken(res.data.access_token)
        // window.localStorage.token = res.data.access_token
        // setType('1')
        // createLoading.value = false
        LoginDisable.value = false;
        // getLoginConsuInfo()
        // router.push('/profileInfo')
        console.log(res);
      } else {
        // createLoading.value = false
        LoginDisable.value = false;
        nextLoad.value = false;
        ElMessage.error({
          grouping: true,
          message: "Verification code error",
        });
      }
    } else {
      console.log("else");
      ElMessage.error({
        grouping: true,
        message: "Incorrect account or Code format",
        center: false,
      });
      return false;
    }
  });
};
const goOnlineExpert = () => {
  router.push("/onlineExpert");
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 70px;
    line-height: 45px;
    background: #02758a;
    color: #ffff;

    .head-left {
      cursor: pointer;
      margin-top: 15px;

      img {
        width: 150px;
        height: 40px;
      }
    }

    .head-right {
      background-color: #e49c17;
      width: 100px;
      line-height: 35px;
      font-size: 12px;
      color: black;
      text-align: center;
      cursor: pointer;
      border-radius: 35px;
    }
  }

  .middle {
    width: 100%;
    background: #deeff3;
    height: 655px;
    overflow: hidden;

    .content {
      width: 800px;
      height: 400px;
      background-color: #fff;
      margin: auto;
      margin-top: 80px;
      margin: auto;

      .content-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        padding: 0 30px;
        margin-top: 118px;

        .head-login {
          color: #0f333a;
          font-size: 20px;
          font-weight: 400;
        }

        .head-live {
          background-color: #1097b3;
          width: 80px;
          text-align: center;
          line-height: 35px;
          color: #fff;
          font-size: 12px;
          border-radius: 3px;
        }
      }

      .content-mid {
        display: flex;
        background-color: #0f0e0e;

        .mid-icon {
          width: 60px;
          line-height: 60px;
          background-color: #d1bd06;
        }

        .mid-text {
          color: #fff;
          line-height: 60px;
          margin-left: 20px;
        }
      }

      .mid-login {
        padding: 30px;
        border-bottom: 1px solid #0f0e0e;

        .demo-ruleForm {
          margin-left: 100px;
        }

        .login-input {
          width: 400px;
          margin-left: 50px;
        }
      }

      .loginW {
        width: 100px;
        margin-left: 130px;
      }

      .forget {
        margin-left: 30px;
        color: #02758a;
        cursor: pointer;
      }

      .mid-footer {
        margin: 20px;
        font-size: 12px;

        .foot1 {
          text-align: center;
          color: #c0b5b5;
        }

        .foot2 {
          text-align: center;
          color: #1097b3;
          margin-top: 5px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {

    
      :deep(.el-form-item__label){
        width:80px !important;
      }


  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    line-height: 45px;
    background: #FFFFFF;
    color: #ffff;

    .head-left {
      cursor: pointer;
      margin-top: 15px;

      img {
        width: 150px;
        height: 40px;
      }
    }

    .head-right {
      background-color: #e49c17;
      width: 100px;
      line-height: 35px;
      font-size: 12px;
      color: black;
      text-align: center;
      cursor: pointer;
      border-radius: 35px;
    }
  }

  .middle {
    width: 100%;
    background: #eff9fb;
    // height: 655px;
    overflow: hidden;

    .content {
    //   width: 800px;
      height: 400px;
      background-color: #fff;
      margin: auto;
      margin-top: 80px;
      margin: auto;

      .content-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        padding: 0 30px;
        margin-top: 118px;

        .head-login {
          color: #0f333a;
          font-size: 20px;
          font-weight: 400;
        }

        .head-live {
          background-color: #1097b3;
          width: 80px;
          text-align: center;
          line-height: 35px;
          color: #fff;
          font-size: 12px;
          border-radius: 3px;
        }
      }

      .content-mid {
        display: flex;
        background-color: #0f0e0e;

        .mid-icon {
          width: 30px;
          line-height: 30px;
          background-color: #d1bd06;
        }

        .mid-text {
          color: #fff;
          line-height: 30px;
          margin-left: 20px;
        }
      }


      .mid-login {
        // padding: 30px;
        border-bottom: 1px solid #0f0e0e;
        margin-top:20px;

        .demo-ruleForm {
        //   margin-left: 100px;
        }

        .login-input {
          width: 400px;
        //   margin-left: 50px;
        }
      }

      .loginW {
        width: 100px;
        margin-left: 130px;
      }

      .forget {
        margin-left: 30px;
        color: #02758a;
        cursor: pointer;
      }

      .mid-footer {
        margin: 20px;
        font-size: 12px;

        .foot1 {
          text-align: center;
          color: #c0b5b5;
        }

        .foot2 {
          text-align: center;
          color: #1097b3;
          margin-top: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
